import styled from 'styled-components';
import { media } from '../../../utils/rwd';

export const Slide = styled.div`
  /* width: 190%; */
  display: flex;
  flex-direction: column;
  /* padding-right: 10px; */
  /* ${media.lg`
    padding-right: 20px;
  `} */
  cursor: pointer;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

export const Name = styled.p`
  margin: 15px 0 0 0;
  color: white;
  font-size: 24px;
  line-height: 27px;

  & * {
    color: inherit;
  }
`;

export const CoverPhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow-y: auto;
  position: relative;
  background-color: white;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
