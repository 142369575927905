import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../../api';

type FromLinkRouteParams = { slug: string };

const slugCasing = (slug: string) => {
  if (slug.length > 5) {
    return slug.toLowerCase();
  }
  return slug.toUpperCase();
};

type StylistResponse = {
  type: 'stylist';
  vanity_url: string;
};

type SalonResponse = {
  type: 'salon';
  vanity_url: string;
};

type LookResponse = {
  type: 'look';
  owner_vanity: string;
};

type ProductResponse = {
  type: 'product';
  vanity_url: string;
};

type Response = StylistResponse | SalonResponse | LookResponse | ProductResponse;

const constructLink = (response: Response, slug: string) => {
  switch (response.type) {
    case 'look':
      return `/a/${response.owner_vanity}/look/${slug}`;
    case 'salon':
      return `/salon/${response.vanity_url}`;
    case 'product':
      return `/${response.vanity_url}`;
    case 'stylist': {
      const link = response.vanity_url || slug;
      return `/a/${link}`;
    }
    default:
      return `/a/${slug}`;
  }
};

export const FromShortLink = () => {
  const { slug = '' } = useParams<FromLinkRouteParams>();
  const navigate = useNavigate();

  useEffect(() => {
    const getPageType = async () => {
      try {
        const slugCased = slugCasing(slug);
        const { data: response } = await api.get(`/v3/from-slug/${slugCased}`);

        if (!['look', 'stylist', 'salon', 'product'].includes(response.type)) {
          throw new Error();
        }

        const newPath = constructLink(response, slugCased);
        window.location.href = newPath + window.location.search;
      } catch {
        navigate('/about', {
          state: {
            from: '',
          },
        });
      }
    };
    getPageType();
  }, [slug]);
  return <div />;
};
