import { FC, ReactElement, useEffect, useState } from 'react';
import { getEnvVariable } from '../..';
import { api } from '../../api';

type TAppInitializerProps = {
  children: ReactElement;
};

export const AppInitializer: FC<TAppInitializerProps> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.baseURL = ['production'].includes(getEnvVariable('BUILD_TYPE') || '')
        ? 'https://elc-production.hausmart.com'
        : 'https://elc-staging.hausmart.com';
      config.headers['X-App-Source'] = 'MAC';
      config.headers.Brand = getEnvVariable('BRAND');
      config.headers.Region = getEnvVariable('REGION');

      return config;
    });

    setInitialized(true);
  }, []);

  if (!initialized) {
    return null;
  }

  return children;
};
