import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const HoverBarWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;

  a {
    transition: color 0.1s ease;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
  }

  * {
    font-family: AvedaSansRegular, Arial, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
  }
`;

export const CategoriesList = styled.ul`
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: 0;
  padding: 15px 10px;
  max-width: 1255px;
  margin: auto;
  text-align: left;
  display: flex;
  list-style: none;
`;

export const Category = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0 15px;
  font-size: 16px;
  vertical-align: top;
  width: auto;
`;

export const CategoryTitle = styled.span<{ $isSmall }>`
  height: 32px;
  display: flex;
  align-items: center;
  color: #120e02;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${(props) => (props.$isSmall ? '12px' : '14px')};
  line-height: 15px;
`;

export const CategoryContentList = styled.ul`
  grid-template-rows: repeat(11, auto);
  grid-auto-columns: minmax(0, 1fr);
  display: grid;
  grid-auto-flow: column;
  margin: 0;
  padding: 0;
  width: max-content;
  list-style: none;
`;

export const LinkWrapper = styled.li`
  line-height: 15px;
  display: flex;
  height: 32px;
  align-items: center;
  margin-left: 32px;
  padding: 0;
  font-weight: 400;

  a {
    color: #120e02;
    text-transform: uppercase;
  }
`;

export const NestedLink = styled.a<{ $isSmall }>`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: ${(props) => (props.$isSmall ? '10px' : '14px')};
  line-height: 15px;
  font-weight: 400;
  font-family: AvedaSansRegular, Arial, Verdana, sans-serif;
`;

export const Link = styled.a<{ $isSmall }>`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: ${(props) => (props.$isSmall ? '10px' : '14px')};
  line-height: 15px;
`;

export const SubCategoryWrapper = styled.li<{ $isSmall }>`
  margin-left: 16px;
  padding: 0;
  height: 32px;

  a {
    color: #120e02;
    text-transform: uppercase;
    line-height: 15px;
    font-size: ${(props) => (props.$isSmall ? '12px' : '14px')};
    font-weight: 700;
    font-family: AvedaSansRegular, Arial, Verdana, sans-serif;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
`;
export const CloseIcon = styled(MdClose).attrs({
  size: 24,
})`
  margin: 10px;
  position: absolute;
  top: 0;
  right: -20px;
  cursor: pointer;
`;
